<template>
  <collapse-transition>
    <div
      v-if="isOpened && isMobileBrowser"
      class="app-promo-bar"
    >
      <div class="app-promo-bar__container container container--huge">
        <div class="app-promo-bar__close-button">
          <button
            class="app-promo-bar__close-button__button"
            type="button"
            @click="close"
          >
            <b class="visually-hidden">{{ $t('mobile-app-promo.close-button') }}</b>
            <svg-icon
              icon-name="cross"
              icon-size="s"
            />
          </button>
        </div>

        <div class="app-promo-bar__icon">
          <img
            src="/assets/images/mobile-app-icon.svg"
            alt=""
            class="app-promo-bar__icon__image"
          >
        </div>

        <div class="app-promo-bar__content">
          <slot name="content" />
        </div>

        <div class="app-promo-bar__action">
          <slot name="action" />
        </div>
      </div>
    </div>
  </collapse-transition>
</template>

<script>
import Cookies from 'js-cookie'
import { isMobileBrowser } from '../utils/mobile'

const COOKIE_EXPIRATION_TIME = 1 // in days
const COOKIE_NAME = 'app-promo-bar'
const COOKIE_VALUE = 'closed'

export default {

  data () {
    return {
      isMobileBrowser: isMobileBrowser(),
      isOpened: Cookies.get(COOKIE_NAME) !== COOKIE_VALUE
    }
  },

  methods: {
    close () {
      Cookies.set(COOKIE_NAME, COOKIE_VALUE, { expires: COOKIE_EXPIRATION_TIME })
      this.isOpened = false
    }
  }

}
</script>
