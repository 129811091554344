import { createApp, defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

// Sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

// Vuex related things
import { createStore, mapGetters } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

import { VueMaskDirective } from 'v-mask'

// Vee-validate
import './vee-validate'

import Zenscroll from 'zenscroll'
import PrlxSetup from './plugins/prlx-setup'
import VueNextSelect from 'vue-next-select'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import 'vue-next-select/dist/index.css'
import 'focus-visible'

import { DatePicker } from 'v-calendar'
import { format, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { hu } from 'date-fns/locale'

import { createI18n } from 'vue-i18n'

import eventHub from './eventHub'
import routes from './routes'

// Components
import AlertBar from './components/AlertBar.vue'
import AmoebaLines from './components/AmoebaLines.vue'
import AppPromoBar from './components/AppPromoBar.vue'
import CheckboxField from './components/CheckboxField.vue'
import CookieSettings from './components/CookieSettings.vue'
import HighlightedArticlesCounter from './components/HighlightedArticlesCounter.vue'
import MobileNavigation from './components/MobileNavigation.vue'
import SvgIcon from './components/SvgIcon.vue'

import './sass/main.scss'

/**
 * Store settings and imports
 */
import boostcom from './store/modules/boostcom'
import cms from './store/modules/cms'
import trendm from './store/modules/trendm'

const router = createRouter({
  history: createWebHistory(),
  routes
})

const persistedState = createPersistedState({
  paths: ['boostcom', 'trendm'],
  storage: {
    getItem: (key) => Cookies.get(key),
    setItem: (key, value) => Cookies.set(key, value, { expires: 7, secure: true, sameSite: 'strict' }),
    removeItem: (key) => Cookies.remove(key)
  }
})

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    boostcom,
    cms,
    trendm
  },
  plugins: [persistedState]
})

const App = createApp({

  data: () => ({
    email: ''
  }),

  computed: {
    ...mapGetters('boostcom', [
      'isLoggedIn',
      'user'
    ])
  },

  mounted () {
    this.$nextTick(() => { PrlxSetup() })
  },

  methods: {
    openMobileNavigation () {
      this.$eventBus.$emit('OPEN_NAVIGATION_MODAL')
    },

    openSearchModal () {
      this.$eventBus.$emit('OPEN_SEARCH_MODAL')
    },

    openLoginModal () {
      this.$eventBus.$emit('OPEN_LOGIN_MODAL')
    },

    openRegisterModal () {
      this.$eventBus.$emit('OPEN_REGISTER_MODAL')
    },

    openOpeningHoursModal () {
      this.$eventBus.$emit('OPEN_OPENING_HOURS_MODAL')
    },

    openParkingInfoModal () {
      this.$eventBus.$emit('OPEN_PARKING_INFO_MODAL')
    },

    openLocationInfoModal () {
      this.$eventBus.$emit('OPEN_LOCATION_INFO_MODAL')
    },

    openShopOpeningHoursModal () {
      this.$eventBus.$emit('OPEN_SHOP_OPENING_HOURS_MODAL')
    },

    openSubscribeModal (email = '') {
      this.$eventBus.$emit('OPEN_SUBSCRIPTION_MODAL', email)
    }
  }

})

Zenscroll.setup(null, 60)

const i18n = createI18n({
  locale: 'default',
  messages: { default: window?.Allee?.i18n }
})

App.use(store)
App.use(router)
App.use(i18n)

App.config.globalProperties.$eventBus = eventHub
App.config.globalProperties.$Allee = window?.Allee

App.config.globalProperties.$filters = {
  parseISODate: value => parseISO(value),
  formatDate: (value, tokens = 'yyyy. MMMM d., HH:mm', options = { locale: hu }) =>
    format(value, tokens, options)
}

App.config.globalProperties.$filters.formatDateInTimeZone = (value, ...args) =>
  App.config.globalProperties.$filters.formatDate(utcToZonedTime(value, 'Europe/Budapest'), ...args)

App.component('AlertBar', AlertBar)
App.component('AmoebaLines', AmoebaLines)
App.component('AppPromoBar', AppPromoBar)
App.component('CheckboxField', CheckboxField)
App.component('CollapseTransition', CollapseTransition)
App.component('CookieSettings', CookieSettings)
App.component('DatePicker', DatePicker)
App.component('HighlightedArticlesCounter', HighlightedArticlesCounter)
App.component('MobileNavigation', MobileNavigation)
App.component('SvgIcon', SvgIcon)
App.component('VueSelect', VueNextSelect)

App.component('AccordionList', defineAsyncComponent(() => import('./components/AccordionList.vue')))
App.component('AppStoreButtons', defineAsyncComponent(() => import('./components/AppStoreButtons.vue')))
App.component('DiscountCard', defineAsyncComponent(() => import('./components/DiscountCard.vue')))
App.component('DiscountModal', defineAsyncComponent(() => import('./components/DiscountModal.vue')))
App.component('EventRegistrationForm', defineAsyncComponent(() => import('./components/EventRegistrationForm.vue')))
App.component('FaqPage', defineAsyncComponent(() => import('./components/FaqPage.vue')))
App.component('HomeEventsSection', defineAsyncComponent(() => import('./components/HomeEventsSection.vue')))
App.component('JobCard', defineAsyncComponent(() => import('./components/JobCard.vue')))
App.component('JobModal', defineAsyncComponent(() => import('./components/JobModal.vue')))
App.component('LocationInfoModal', defineAsyncComponent(() => import('./components/LocationInfoModal.vue')))
App.component('LoginModal', defineAsyncComponent(() => import('./components/LoginModal.vue')))
App.component('OpeningHoursModal', defineAsyncComponent(() => import('./components/OpeningHoursModal.vue')))
App.component('ParkingInfoModal', defineAsyncComponent(() => import('./components/ParkingInfoModal.vue')))
App.component('PrizeGameRegistrationForm', defineAsyncComponent(() => import('./components/PrizeGameRegistrationForm.vue')))
App.component('ProfilePage', defineAsyncComponent(() => import('./components/ProfilePage.vue')))
App.component('PromoModal', defineAsyncComponent(() => import('./components/PromoModal.vue')))
App.component('RegisterModal', defineAsyncComponent(() => import('./components/RegisterModal.vue')))
App.component('SearchModal', defineAsyncComponent(() => import('./components/SearchModal.vue')))
App.component('SearchResults', defineAsyncComponent(() => import('./components/SearchResults.vue')))
App.component('ServicesPage', defineAsyncComponent(() => import('./components/ServicesPage.vue')))
App.component('ShopCard', defineAsyncComponent(() => import('./components/ShopCard.vue')))
App.component('ShopOpeningHoursModal', defineAsyncComponent(() => import('./components/ShopOpeningHoursModal.vue')))
App.component('ShopsPage', defineAsyncComponent(() => import('./components/ShopsPage.vue')))
App.component('StylistCard', defineAsyncComponent(() => import('./components/StylistCard.vue')))
App.component('StylistModal', defineAsyncComponent(() => import('./components/StylistModal.vue')))
App.component('StylistsList', defineAsyncComponent(() => import('./components/StylistsList.vue')))
App.component('StylistTimeslotCard', defineAsyncComponent(() => import('./components/StylistTimeslotCard.vue')))
App.component('SubscriptionModal', defineAsyncComponent(() => import('./components/SubscriptionModal.vue')))
App.component('TiktokEmbed', defineAsyncComponent(() => import('./components/TiktokEmbed.vue')))
App.component('WorkshopRegistrationForm', defineAsyncComponent(() => import('./components/WorkshopRegistrationForm.vue')))

App.directive('mask', {
  beforeMount: VueMaskDirective.bind,
  updated: VueMaskDirective.componentUpdated,
  unmounted: VueMaskDirective.unbind
})

Sentry.init({
  App,
  dsn: import.meta.env.VITE_SENTRY_DSN || null,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['staging.allee.hu', 'allee.hu', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  release: import.meta.env.VITE_APP_VERSION || 'not_set',
  environment: 'production',
  logErrors: true,
  tracesSampleRate: 0.0
})

App.mount('#app')
